/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createStaffDocument,
  getStaffDocument,
  getStaffDocumentTenant,
  listStaffDocuments,
  updateStaffDocument,
  updateStaffDocumentTenant,
} from '@digistaff/staff';
import moment from 'moment';
import {app, setTenantKey} from '../Accounts';

import {withDefaultTenant, withTenant} from './withTenant';
import {createStaffDocumentInTenant, fetchAgencyList} from './tenancy';
import {handleCustomError} from '../../views/RouteErrorView/RouteErrorView';
import {getPayloadHash} from '@smithy/signature-v4';

export const listDocumentList = async (staffId: string, type?: any) => {
  try {
    const filterCriteria = {staff_id: staffId, type: type};

    const response = await listStaffDocuments(app, {
      filter: filterCriteria,
    });
    return response.data.listStaffDocuments;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const listDocumentListInDefault = async (
  tenant_id: string,
  staffId: string
) => {
  const currentTenant = tenant_id;
  setTenantKey(app, 'default');
  try {
    const filterCriteria = {staff_id: staffId};

    const response = await listStaffDocuments(app, {
      filter: filterCriteria,
    });
    return response?.data?.listStaffDocuments;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  } finally {
    setTenantKey(app, currentTenant);
  }
};

export const listMandatoryDocuments = async (staffId: string) => {
  try {
    const filterCriteria = {
      staff_id: staffId,
      type: {operator: 'like', value: 'mandatory%'},
    };

    const response = await listStaffDocuments(app, {
      filter: filterCriteria,
    });
    return response.data.listStaffDocuments;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createGovernmentDocument = async (
  staffId: string,
  cert: string,
  govPhoto: string
) => {
  try {
    const sessionStaffId = staffId ?? sessionStorage.getItem('email');

    const formattedType =
      cert === 'passport'
        ? 'mandatory_government_passport'
        : 'mandatory_government_driverLicense';

    const payload = {
      staff_id: sessionStaffId,
      type: formattedType,
      file_url: govPhoto,
      reference_id: '',
    };

    return await createStaffDocument(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const getGovernmentDocument = async (govDocId: string): Promise<any> => {
  try {
    const res = await getStaffDocument(app, govDocId);
    const retrievedData = res.data.getStaffDocument;

    if (retrievedData) {
      const formattedType =
        retrievedData.type === 'mandatory_government_passport'
          ? 'passport'
          : 'driver license';

      const retrievedDoc = {
        doc_type: formattedType,
        _photo: retrievedData.file_url,
      };
      return retrievedDoc;
    } else {
      return '';
    }
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateGovernmentDocument = async (
  govDocId: string,
  cert: string,
  govPhoto: string
) => {
  try {
    const formattedType =
      cert === 'passport'
        ? 'mandatory_government_passport'
        : 'mandatory_government_driverLicense';

    const payload = {
      type: formattedType,
      file_url: govPhoto,
    };

    return await updateStaffDocument(app, govDocId, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const getSinDocument = async (sinDocId: string) => {
  try {
    const res = await getStaffDocument(app, sinDocId);
    const retrievedData = res?.data?.getStaffDocument;

    if (retrievedData) {
      const formattedType =
        retrievedData.type === 'mandatory_sin_default' ? 'sin' : 'cra';
      const formattedExpiry = moment(retrievedData.expiration);

      const retrievedDoc = {
        doc_type: formattedType,
        doc_no: retrievedData.reference_id,
        expiry_date: formattedExpiry,
        _photo: retrievedData.file_url,
      };

      return retrievedDoc;
    } else {
      return '';
    }
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createSinDocument = async (
  staffId: string,
  cert: string,
  docNo: string,
  expiry: any,
  photo: string
) => {
  try {
    const sessionStaffId = staffId ?? sessionStorage.getItem('email');
    const isValidExpiry = expiry && moment(expiry).isValid();
    const formattedExpiry = isValidExpiry ? moment(expiry).toISOString() : null;

    const formattedType =
      cert === 'sin' ? 'mandatory_sin_default' : 'mandatory_sin_cra';

    const payload: any = {
      staff_id: sessionStaffId,
      type: formattedType,
      expiration: formattedExpiry,
      reference_id: docNo,
      file_url: photo,
    };

    return await createStaffDocument(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

// export const createUploadResume = async (
//   staffId: string,
//   resume_url: any,
//   file: any
// ) => {
//   const sessionStaffId = staffId ?? sessionStorage.getItem('email');
//   try {
//     const payload: any = {
//       staff_id: sessionStaffId,
//       type: file.type,
//       resume_url: resume_url,
//     };

//     return await createStaffDocument(app, payload);
//   } catch (err) {
//     console.log(err);
//     console.log(err);
//     return Promise.reject(err);
//   }
// };

export const updateSinDocument = async (
  docId: string,
  cert: string,
  docNo: string,
  expiry: any,
  photo: string
) => {
  const isValidExpiry = expiry && moment(expiry).isValid();
  const formattedExpiry = isValidExpiry ? moment(expiry).toISOString() : null;
  const formattedType =
    cert === 'sin' ? 'mandatory_sin_default' : 'mandatory_sin_cra';

  const payload: any = {
    type: formattedType,
    expiration: formattedExpiry,
    reference_id: docNo,
    file_url: photo,
  };

  try {
    return await updateStaffDocument(app, docId, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createWorkDocument = async (
  staffId: string,
  workStatus: string,
  photo: string
) => {
  try {
    const formattedType = workStatusMappingToApi[workStatus];

    const payload: any = {
      staff_id: staffId,
      type: formattedType,
      file_url: photo,
      reference_id: '',
    };

    return await createStaffDocument(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

interface FileData {
  base64: string;
  file: File;
}

export const getWorkDocument = async (workDocId: string) => {
  try {
    const res = await getStaffDocument(app, workDocId);
    const retrievedData = res?.data?.getStaffDocument;

    if (retrievedData) {
      const formattedType = workStatusMappingFromGQL[retrievedData.type];

      const retrievedDoc = {
        doc_type: formattedType,
        _photo: retrievedData.file_url,
      };

      return retrievedDoc;
    } else {
      return '';
    }
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateWorkDocument = async (
  docId: string,
  workStatus: string,
  photo: string
) => {
  const formattedType = workStatusMappingToApi[workStatus];

  const payload: any = {
    type: formattedType,
    file_url: photo,
  };

  try {
    return await updateStaffDocument(app, docId, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchStaffDocumentTenant = async (docId: string) => {
  try {
    const res = await getStaffDocumentTenant(app, docId);

    return res.data.getStaffDocumentTenant;
  } catch (error) {
    console.log(error);
    console.log(error);
    return error;
  }
};

export const updateStaffDocumentInTenant = async (
  docId: string,
  type: string,
  docType: string
) => {
  try {
    let formattedDocType: any;

    if (type === 'work') {
      formattedDocType = workStatusMappingToApi[docType];
    } else if (type === 'sin') {
      formattedDocType =
        docType === 'sin' ? 'mandatory_sin_default' : 'mandatory_sin_cra';
    } else if (type === 'gov') {
      formattedDocType =
        docType === 'passport'
          ? 'mandatory_government_passport'
          : 'mandatory_government_driverLicense';
    }

    const payload = {
      type: formattedDocType,
      status:
        formattedDocType === 'mandatory_work_applied'
          ? DocumentStatus.approved
          : DocumentStatus.pending,
    };

    return await updateStaffDocumentTenant(app, docId, payload);
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const createStaffDocInGlobal = async (
  staffId: string,
  docId: string,
  type: string
): Promise<any> => {
  try {
    const tenantList = await fetchAgencyList(staffId);

    if (tenantList && tenantList.length !== 0) {
      for (const tenantId of tenantList) {
        const payload = {
          id: docId,
          type: type,
        };
        await createStaffDocumentInTenant(staffId!, tenantId, [payload]);
      }
    }
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const updateTenantStaffDocuments = (tenant_id: string) =>
  withTenant(tenant_id, updateStaffDocumentInTenant);

export const checkSinUniqueness = async (sinNo: string) => {
  const currentTenant = localStorage.getItem('tenantId');
  if (currentTenant !== 'default') {
    setTenantKey(app, 'default');
  }
  try {
    const response = await listStaffDocuments(app, {
      filter: {reference_id: sinNo},
      limit: 1,
    });
    return response.data.listStaffDocuments;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  } finally {
    setTenantKey(app, currentTenant!);
  }
};

export const allMandatoryFromGQL: any = {
  mandatory_government_passport: 'government ID',
  mandatory_government_driverLicense: 'government ID',
  mandatory_sin_default: 'SIN',
  mandatory_sin_cra: 'SIN',
  mandatory_work_permanentResident: 'eligibility to work',
  mandatory_work_citizenship: 'eligibility to work',
  mandatory_work_workPermit: 'eligibility to work',
  mandatory_work_studyPermit: 'eligibility to work',
  mandatory_work_applied: 'eligibility to work',
};

export const govDocTypeFromGQL: any = {
  mandatory_government_passport: 'passport',
  mandatory_government_driverLicense: 'driver license',
};

export const sinDocTypeFromGQL: any = {
  mandatory_sin_default: 'SIN',
  mandatory_sin_cra: 'CRA',
};

export const govStatusList = [
  'mandatory_government_passport',
  'mandatory_government_driverLicense',
];

export const sinStatusList = ['mandatory_sin_default', 'mandatory_sin_cra'];

export const workStatusList = [
  'mandatory_work_permanentResident',
  'mandatory_work_citizenship',
  'mandatory_work_workPermit',
  'mandatory_work_studyPermit',
  'mandatory_work_applied',
];

export const workStatusMappingFromGQL: any = {
  mandatory_work_permanentResident: 'permanent resident',
  mandatory_work_citizenship: 'citizenship',
  mandatory_work_workPermit: 'work permit',
  mandatory_work_studyPermit: 'study permit',
  mandatory_work_applied: 'applied status',
};

export const workStatusMappingToApi: any = {
  'permanent resident': 'mandatory_work_permanentResident',
  citizenship: 'mandatory_work_citizenship',
  'work permit': 'mandatory_work_workPermit',
  'study permit': 'mandatory_work_studyPermit',
  'applied status': 'mandatory_work_applied',
};

export enum DocStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REMOVED = 'removed',
}

export enum DocumentStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  resubmit = 'resubmit',
}
