import {useEffect, useState} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Typography,
} from '@mui/material';

import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {userScrollLimit} from '../../LandingView/LandingViewStyles';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import FileUploader from '../../../../components/common/UploadFile/FileUploader';
import {convertToBase64} from '../../../../utils/base64Converter';
import {continueBtn} from '../../LandingView/LandingViewStyles';
import {useFileUpload} from '../../../../hooks/useFileUpload';
import DownloadIcon from '@mui/icons-material/Download';
// import {createUploadResume} from '../../../../providers/api/documents';
import {
  fetchUserProfileFull,
  getUserPersonalInfo,
  updateUserProfileResume,
} from '../../../../providers/api/profile';
import {getFileName} from '../../../../utils/common';
import {useNavigate} from 'react-router-dom';

interface FileData {
  file: File;
  base64: string;
}

export const UploadResume = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [resumeLoading, setResumeLoading] = useState(true);
  const [fileData, setFileData] = useState<{
    file: File | null;
    base64: string | null;
  }>({
    file: null,
    base64: null,
  });

  const {handlePdfFileUpload} = useFileUpload();
  const staffId = sessionStorage.getItem('email')?.toLowerCase();

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    const type = {operator: 'like', value: '%mandatory_work%'};
    fetchUserProfileFull(staffId!, type)
      .then((res: any) => {
        setUserProfile(res);
      })
      .catch(err => {
        console.log(err);
        console.log(err);
      })
      .finally(() => setResumeLoading(false));
  };

  const onFileUploader = async (file: File) => {
    try {
      const base64Data = await convertToBase64(file);
      setFileData({
        file,
        base64: base64Data,
      });
    } catch (error) {
      console.error('Error converting file to base64:', error);
    }
  };

  const handleUploadResume = async () => {
    setLoading(true);

    if (fileData.file && fileData.base64 && staffId) {
      try {
        const validFileData: FileData = {
          file: fileData.file,
          base64: fileData.base64,
        };

        const uploadedFile = await handlePdfFileUpload(staffId, validFileData);
        const resume_url = uploadedFile?.data?.result?.Location;

        await updateUserProfileResume(staffId!, resume_url)
          .then(res => navigate('/info/id'))
          .catch(error => console.error(error))
          .finally(() => setLoading(false));
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/id" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Upload Resume"></PageWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {userProfile?.resume ? (
            <Box
              sx={{
                mb: 2,
                p: 2,
                width: {xs: '100%', sm: '500px'},
                border: '1px solid #ccc',
                borderRadius: 2,
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getFileName(userProfile.resume)}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'row'},
                    gap: 2,
                  }}
                >
                  {/* Download Resume Button remains unchanged */}

                  <Button
                    variant="outlined"
                    href={userProfile.resume}
                    sx={{borderRadius: 1}}
                    startIcon={<DownloadIcon />}
                  >
                    Download
                  </Button>
                </Box>
              </>
            </Box>
          ) : null}
        </Box>

        <FileUploader onFileUpload={onFileUploader} />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: 3,
          }}
        >
          <Button
            variant="outlined"
            sx={{...continueBtn, width: {xs: '100%', sm: '500px'}}}
            onClick={handleUploadResume}
            disabled={!fileData.base64}
          >
            {loading ? (
              <CircularProgress
                size="1.3rem"
                sx={{color: 'primary.light', fontSize: '12px'}}
              />
            ) : (
              'Upload Resume'
            )}
          </Button>
          {/* <Button
            id="submit-btn"
            variant="outlined"
            sx={continueBtn}
            disabled={submitting}
            onPointerUp={handleSubmit}
          >
            {submitting ? (
              <CircularProgress
                size="1.3rem"
                sx={{color: 'primary.light', fontSize: '12px'}}
              />
            ) : (
              'Save'
            )}
          </Button> */}
        </Box>
      </Box>
    </>
  );
};
