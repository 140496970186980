// Extract the file name from the resume URL
export const getFileName = (url: string): string => {
  try {
    const parts = url.split('/');
    const fileName = decodeURIComponent(parts[parts.length - 1]);
    return fileName.replace(/^\d+_/, '');
  } catch (error) {
    return 'Resume';
  }
};
