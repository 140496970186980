import React, {useEffect, useState} from 'react';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {Box, Button, Skeleton, Typography} from '@mui/material';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {continueBtn} from '../../../../Public/LandingView/LandingViewStyles';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import FileUploader from '../../../../../components/common/UploadFile/FileUploader';
import {bottomSubPageLimit} from '../../ProfileViewStyles';
import {convertToBase64} from '../../../../../utils/base64Converter';
import {useFileUpload} from '../../../../../hooks/useFileUpload';
import {usePopUp} from '../../../../../hooks/usePopUp';
import DownloadIcon from '@mui/icons-material/Download';
import {
  fetchUserProfileFull,
  updateStaffProfileWithPayload,
} from '../../../../../providers/api/profile';
import {PopUp} from '../../../../../components/common/PopUp/PopUp';
import {getFileName} from '../../../../../utils/common';

interface FileData {
  file: File;
  base64: string;
}

const ViewResume = () => {
  const [userProfile, setUserProfile] = useState<any>(null);
  const [resumeLoading, setResumeLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState<{
    file: File | null;
    base64: string | null;
  }>({
    file: null,
    base64: null,
  });
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const {handlePdfFileUpload} = useFileUpload();

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    const staffId = localStorage.getItem('email');
    console.log('test');
    const type = {operator: 'like', value: '%mandatory_work%'};
    fetchUserProfileFull(staffId!, type)
      .then((res: any) => {
        setUserProfile(res);
      })
      .catch(err => {
        console.log(err);
        console.log(err);
      })
      .finally(() => setResumeLoading(false));
  };

  const onFileUploader = async (file: File) => {
    try {
      const base64Data = await convertToBase64(file);
      setFileData({
        file,
        base64: base64Data,
      });
    } catch (error) {
      console.error('Error converting file to base64:', error);
    }
  };

  const handleUpdateResume = async () => {
    setLoading(true);
    const staffId: any = localStorage.getItem('email');
    if (fileData.file && fileData.base64 && staffId) {
      try {
        // Now we know fileData has both properties
        const validFileData: FileData = {
          file: fileData.file,
          base64: fileData.base64,
        };
        const uploadedFile = await handlePdfFileUpload(staffId, validFileData);
        const resumeUrl = uploadedFile?.data?.result?.Location;
        const payload = {
          resume_url: resumeUrl,
        };
        await updateStaffProfileWithPayload(staffId, payload)
          .then(res => {
            handlePopUp('success', 'Your resume has been updated');
            loadProfile();
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => setLoading(false));
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={{bottomSubPageLimit}}>
        <PageWrapper title="Upload Resume">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {resumeLoading ? (
              <Skeleton
                variant="rounded"
                width={500}
                height={70}
                sx={{mb: 2}}
              />
            ) : (
              <Box
                sx={{
                  mb: 2,
                  p: 2,
                  width: {xs: '100%', sm: '500px'},
                  border: '1px solid #ccc',
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: {xs: 'column', md: 'row'},
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {userProfile?.resume ? (
                  <>
                    <Typography
                      variant="h6"
                      sx={{
                        mb: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {getFileName(userProfile.resume)}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'},
                        gap: 2,
                      }}
                    >
                      {/* Download Resume Button remains unchanged */}

                      <Button
                        variant="outlined"
                        href={userProfile.resume}
                        sx={{borderRadius: 1}}
                        startIcon={<DownloadIcon />}
                      >
                        Download
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Typography>No resume available.</Typography>
                )}
              </Box>
            )}
          </Box>

          <FileUploader onFileUpload={onFileUploader} />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                ...continueBtn,
                width: {xs: '100%', sm: '500px'},
                borderRadius: 1,
              }}
              onClick={handleUpdateResume}
              disabled={!fileData.base64 || loading}
            >
              {loading ? 'Updating...' : 'Update Resume'}
            </Button>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};

export default ViewResume;
