/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import {
  listDocumentList,
  workStatusMappingFromGQL,
} from '../../../providers/api/documents';
import {
  getUserAccountInfo,
  getUserPersonalInfo,
} from '../../../providers/api/profile';
import {getFileName} from '../../../utils/common';

export interface AccountFormProps {
  firstName: string;
  lastName: string;
  phone: string;
  error: any;
  setError: Function;
}

export interface PersonalFormProps {
  gender: string;
  vaccineStatus: string;
  birthday: any;
  location: any;
  error: any;
  setError: Function;
}

export const checkAccForm = ({
  firstName,
  lastName,
  phone,
  error,
  setError,
}: AccountFormProps) => {
  let tempError = {...error};
  const phoneValidity = phone && isPossiblePhoneNumber(phone);

  if (!firstName && !lastName) {
    tempError = {...tempError, name: 'Please provide first or last name'};
  } else {
    tempError = {...tempError, name: ''};
  }
  if (
    phoneValidity === false ||
    !phoneValidity ||
    phoneValidity === undefined
  ) {
    tempError = {...tempError, phone: 'Please provide a valid phone number'};
  } else {
    tempError = {...tempError, phone: ''};
  }
  setError(tempError);
};

export const checkPersonalForm = ({
  gender,
  vaccineStatus,
  birthday,
  location,
  error,
  setError,
}: PersonalFormProps) => {
  const birthdayMoment = moment(birthday, 'DD/MM/YYYY');

  let tempError = {...error};
  if (!gender) {
    tempError = {...tempError, gender: 'Please indicate your gender'};
  } else {
    tempError = {...tempError, gender: ''};
  }
  if (!vaccineStatus) {
    tempError = {
      ...tempError,
      vaccineStatus: 'Please provide your vaccination status',
    };
  } else {
    tempError = {...tempError, vaccineStatus: ''};
  }
  if (
    !birthdayMoment ||
    !birthdayMoment.isValid() ||
    moment().diff(birthdayMoment, 'years') < 18
  ) {
    tempError = {
      ...tempError,
      birthday:
        'Please indicate your birthday. You should be at least 18 years old.',
    };
  } else {
    tempError = {...tempError, birthday: ''};
  }
  if (!location || !location.city || !location.country) {
    tempError = {
      ...tempError,
      address: 'Please indicate your address',
    };
  } else {
    tempError = {...tempError, address: ''};
  }

  setError(tempError);
};

export const loadWorkerProfile = async (staffId: string) => {
  const workerProfile = [];

  try {
    const accountInfo = await getUserAccountInfo(staffId);
    const personalInfo = await getUserPersonalInfo(staffId);
    const docInfo = await listDocumentList(staffId);

    const govDoc = docInfo.filter((doc: any) =>
      doc.type.startsWith('mandatory_government')
    );

    const sinDoc = docInfo.filter((doc: any) =>
      doc.type.startsWith('mandatory_sin')
    );

    const workDoc = docInfo.filter((doc: any) =>
      doc.type.startsWith('mandatory_work')
    );

    const staffPhone = accountInfo.phoneRetrieved;

    if (personalInfo) {
      workerProfile.push(
        {
          title: 'name',
          property: `${accountInfo.first_name} ${accountInfo.middle_name} ${accountInfo.last_name}`,
          nav: '/info/account',
        },
        {
          title: 'phone',
          property: accountInfo.phoneRetrieved,
          nav: '/info/account',
        },
        {
          title: 'gender',
          property: personalInfo.genderRetrieved,
          nav: '/info/personal',
        },
        {
          title: 'birthday',
          property: moment(personalInfo.birthdayRetrieved).format(
            'DD MMM YYYY'
          ),
          nav: '/info/personal',
        },
        {
          title: 'vaccination status',
          property: personalInfo.vaccineStatusRetrieved,
          nav: '/info/personal',
        },
        {
          title: 'address',
          property: `${personalInfo.address_unit} ${personalInfo.addressRetrieved.street}, ${personalInfo.addressRetrieved.city}, ${personalInfo.addressRetrieved.province} ${personalInfo.addressRetrieved.postal}, ${personalInfo.addressRetrieved.country}`,
          nav: '/info/personal',
        },
        {
          title: 'government ID type',
          property:
            govDoc[0].type === 'mandatory_government_driverLicense'
              ? 'Driver License'
              : 'Passport',
          nav: '/info/id',
        },
        {
          title: 'resume',
          property: `${getFileName(personalInfo?.resume_url)} `,
          nav: '/info/id',
        }
      );

      workerProfile.push({
        title: 'Proof of SIN',
        property: sinDoc[0].type === 'mandatory_sin_default' ? 'SIN' : 'CRA',
        nav: '/info/id',
      });

      sinDoc[0].expiration &&
        workerProfile.push({
          title: 'SIN expiration',
          property: moment(sinDoc[0].expiration).format('DD MMM YYYY'),
          nav: '/info/id',
        });

      workerProfile.push({
        title: 'work status',
        property: workStatusMappingFromGQL[workDoc[0]?.type],
        nav: '/info/id',
      });
    }

    return {workerProfile, govDoc, sinDoc, workDoc, staffPhone};
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const retrieveData = (key: string, setFunction: Function) => {
  const data = localStorage.getItem(key);
  data && setFunction(data);
};

export const retrievePrev = (key: string, setFunction: Function) => {
  const data = localStorage.getItem(key);
  data && setFunction((prevState: any) => ({...prevState, [key]: data}));
};

export const retrieveDate = (key: string, setFunction: Function) => {
  const data = localStorage.getItem(key);
  if (data) {
    const momentData = moment(data, 'DD/MM/YYYY');
    setFunction(momentData);
  }
};

export const retrieveLocation = (key: string, setFunction: Function) => {
  const data = localStorage.getItem(key);
  if (data) {
    const parsedData = JSON.parse(data);
    if (parsedData.street) {
      const addressTemp = `${parsedData.street}, ${parsedData.city} ${parsedData.province} ${parsedData.postal}, ${parsedData.country}`;
      setFunction(addressTemp);
    }
  }
};
