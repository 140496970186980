/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {getCurrentUser, setTenantKey} from '@digistaff/app';
import {
  createStaffProfile,
  getStaffProfile,
  updateStaffProfile,
  listStaffVendors,
  setMyFCMToken,
  listAllStaff,
  listStaffDocuments,
  getStaffProfileTenant,
  getStaffProfileTenantWithoutNestStaffProfile,
  getStaffProfileCrossTenant,
  updateStaffProfileCrossTenant,
} from '@digistaff/staff';
import moment, {Moment} from 'moment';
import {app} from '../Accounts';
import {listDocumentList, workStatusMappingFromGQL} from './documents';

import {handleCustomError} from '../../views/RouteErrorView/RouteErrorView';
import {withDefaultTenant} from './withTenant';

export interface RegisterUserProps {
  email: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  phone: string;
  cognito_password: string;
}

export interface RegUpdateProfileProps {
  staffId: any;
  gender: string;
  vaccineStatus: string;
  birthday: Moment | null;
  unit: string | null;
  location: any;
}

export const vaccineMappingFromGQL: any = {
  '0': 'none',
  dose_1: '1 dose',
  dose_2: '2 doses',
  booster_1: '1 booster',
  booster_2: '2 boosters',
  booster_3: '3 boosters',
};

export const vaccineStatusMappingToApi: any = {
  none: '0',
  '1 dose': 'dose_1',
  '2 doses': 'dose_2',
  '1 booster': 'booster_1',
  '2 boosters': 'booster_2',
  '3 boosters': 'booster_3',
};

export const genderMappingFromGQL: any = {
  female: 'female',
  male: 'male',
  another: 'another gender',
};

export const genderMappingToApi: any = {
  female: 'female',
  male: 'male',
  'another gender': 'another',
};

export const registerUser = async ({
  email,
  firstName,
  middleName,
  lastName,
  phone,
  cognito_password,
}: RegisterUserProps) => {
  const e = email.trim();
  const f = firstName.trim();
  const m = middleName?.trim();
  const l = lastName.trim();
  try {
    const payload: any = {
      id: e,
      first_name: f,
      middle_name: m,
      last_name: l,
      birthdate: null,
      gender: null,
      email: e,
      phone: phone,
      covid_vaccination: null,
      street: null,
      city: null,
      postal: null,
      province: null,
      country: null,
      latitude: 0,
      longitude: 0,
      current_onboarding_step: 4,
      cognito_password,
      is_create_cognito_user: true,
      resume_url: null,
    };

    const res = await createStaffProfile(app, payload);

    return res;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const checkOnboardingStatus = async () => {
  try {
    let staffId;
    staffId = localStorage.getItem('email');
    if (!staffId) {
      const currentUser: any = await getCurrentUser(app);
      staffId = currentUser?.user?.Username;
    }

    if (!staffId) return;

    const profileRes = await getStaffProfile(app, staffId);

    return profileRes?.data?.getStaffProfile?.current_onboarding_step;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const getUserAccountInfo = async (staffId: string) => {
  try {
    const res = await getStaffProfile(app, staffId);
    if (!res.data.getStaffProfile)
      return {
        first_name: '',
        middle_name: '',
        last_name: '',
        phoneRetrieved: '',
      };

    const {first_name, middle_name, last_name, phone} =
      res.data.getStaffProfile;

    const phoneRetrieved = phone;

    return {first_name, middle_name, last_name, phoneRetrieved};
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getUserPersonalInfo = (staffId: string) => {
  return getStaffProfile(app, staffId)
    .then(res => {
      const {
        birthdate,
        gender,
        covid_vaccination,
        address_unit,
        street,
        city,
        latitude,
        longitude,
        province,
        postal,
        country,
        resume_url,
      } = res.data.getStaffProfile;

      const address = {
        street,
        city,
        province,
        postal,
        country,
        latitude,
        longitude,
      };

      const formattedGender = genderMappingFromGQL[gender];

      const formattedVaccineStatus = vaccineMappingFromGQL[covid_vaccination];

      const personalInfo = {
        birthdayRetrieved: birthdate,
        genderRetrieved: formattedGender,
        vaccineStatusRetrieved: formattedVaccineStatus,
        address_unit,
        addressRetrieved: address,
        resume_url: resume_url,
      };

      return personalInfo;
    })
    .catch(err => console.log(err));
};

export const updateUserProfileInBasicInfo = async (
  profileId: string,
  firstName: string,
  middleName: string,
  lastName: string,
  phone: string
) => {
  try {
    const payload = {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      phone: phone,
    };
    return await updateStaffProfile(app, profileId, payload);
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const updateUserProfileResume = async (
  profileId: string,
  resume_url: string
) => {
  try {
    const payload = {
      resume_url: resume_url,
    };
    return await updateStaffProfile(app, profileId, payload);
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const updateUserProfileInPersonal = async ({
  staffId,
  gender,
  vaccineStatus,
  birthday,
  unit,
  location,
}: RegUpdateProfileProps) => {
  const formattedBirthDay =
    birthday && new Date(birthday.valueOf()).toISOString();

  const formattedVaccineStatus =
    vaccineStatusMappingToApi[vaccineStatus] || '0';
  const formattedGender = genderMappingToApi[gender];

  const payload: any = {
    gender: formattedGender,
    covid_vaccination: formattedVaccineStatus,
    birthdate: formattedBirthDay as string,
    address_unit: unit && unit,
    street: location.street,
    city: location.city,
    province: location.province,
    postal: location.postal,
    country: location.country,
    latitude: location.latitude,
    longitude: location.longitude,
  };
  try {
    return await updateStaffProfile(app, staffId, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchProfileCard = async (staffId: string) => {
  try {
    const profileRes = await getStaffProfile(app, staffId);

    const profileTemp = profileRes?.data?.getStaffProfile;

    const fullName = profileTemp.middleName
      ? `${profileTemp.first_name} ${profileTemp.middle_name} ${profileTemp.last_name}`
      : `${profileTemp.first_name} ${profileTemp.last_name}`;

    const profile = {
      id: profileTemp.id,
      digistaffId: profileTemp.digistaff_id,
      avatar: profileTemp.avatar,
      fullName: fullName,
      phone: profileTemp.phone,
      email: profileTemp.email,
      location: `${profileTemp.city}, ${profileTemp.province}`,
      resume_url: profileTemp?.resume_url,
    };

    return profile;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchUserProfileFull = async (staffId: string, type: any) => {
  try {
    const profileRes = await getStaffProfile(app, staffId);
    console.log({profileRes});
    const profileTemp = profileRes.data.getStaffProfile;
    const docRes = await listDocumentList(staffId, type);
    const workStatusDoc = docRes.filter((doc: any) =>
      doc.type.startsWith('mandatory_work')
    );
    const formattedWorkStatus = workStatusMappingFromGQL[workStatusDoc[0].type];

    const formattedAddress = profileTemp.address_unit
      ? `${profileTemp.address_unit} ${profileTemp.street}, ${profileTemp.city} ${profileTemp.province} ${profileTemp.postal}, ${profileTemp.country}`
      : `${profileTemp.street}, ${profileTemp.city} ${profileTemp.province} ${profileTemp.postal}, ${profileTemp.country}`;

    const profile = {
      id: profileTemp.id,
      digistaffId: profileTemp.digistaff_id,
      avatar: profileTemp.avatar,
      firstName: profileTemp.first_name,
      middleName: profileTemp.middle_name ? profileTemp.middle_name : '',
      lastName: profileTemp.last_name,
      phone: profileTemp.phone,
      email: profileTemp.email,
      location: `${profileTemp.city}, ${profileTemp.province}`,
      address: formattedAddress,
      birthday: moment(profileTemp.birthdate).format('DD MMM YYYY'),
      gender: profileTemp.gender,
      resume: profileTemp?.resume_url,
      workStatus: formattedWorkStatus,
    };

    return profile;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateStaffProfileWithPayload = async (
  staffId: string,
  payload: any
) => {
  try {
    return await updateStaffProfile(app, staffId, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const getUserNameAndPic = async (userID: string) => {
  try {
    const profileRes = await getStaffProfile(app, userID);
    const profileTemp = profileRes.data.getStaffProfile;

    const returnProfile = {
      first_name: profileTemp.first_name,
      last_name: profileTemp.last_name,
      avatar: profileTemp.avatar,
    };

    return returnProfile;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const getDigiStaffId = async (staffId: string) => {
  try {
    const profileRes = await getStaffProfile(app, staffId);

    const digiStaffId = profileRes.data.getStaffProfile.digistaff_id;

    return digiStaffId;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const getVendorList = async (staffId: string) => {
  try {
    const filterCriteria = {staff_id: staffId};

    const vendorRes: any = await listStaffVendors(app, {
      filter: filterCriteria,
    });

    const vendorList = vendorRes.data.listStaffVendors;

    return vendorList;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const setStaffFCMToken = async (fcm_token: any) => {
  try {
    return await setMyFCMToken(app, fcm_token);
  } catch (error) {
    console.error('error in setStaffFCMToken', error);
    return Promise.resolve();
  }
};

export const fetchStaffProfileRaw = async (staffId: string) => {
  try {
    const profileRes = await getStaffProfile(app, staffId);

    return profileRes.data.getStaffProfile;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const checkStaffProfileUniqueness = async (
  email: string,
  phoneNo: string
) => {
  try {
    const emailCheck: any = await listAllStaff(app, {filter: {email: email}});
    const phoneCheck: any = await listAllStaff(app, {filter: {phone: phoneNo}});

    const combinedCheck = [
      ...emailCheck.data.listStaffProfiles,
      ...phoneCheck.data.listStaffProfiles,
    ];

    if (combinedCheck.length !== 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const checkStaffPhoneUniqueness = async (
  phoneNo: string,
  staffId: string
) => {
  try {
    const phoneCheck: any = await listAllStaff(app, {
      filter: {
        phone: phoneNo,
        id: {
          operator: '<>',
          value: staffId,
        },
      },
    });

    return phoneCheck?.data?.listStaffProfiles?.length !== 0;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};

export const checkStaffSinUniqueness = async (sinNo: string) => {
  try {
    const res = await listStaffDocuments(app, {
      filter: {reference_id: sinNo},
      limit: 1,
    });
    if (res?.data?.listStaffDocuments?.length !== 0) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};

export const checkStaffSinUniquenessInApp = withDefaultTenant(
  checkStaffSinUniqueness
);

export const updateStaffProfileSilentMode = async (silentStatus: boolean) => {
  const currentTenant = localStorage.getItem('tenantId');
  if (currentTenant !== 'default') {
    setTenantKey(app, 'default');
  }
  try {
    const staffId = localStorage.getItem('email');

    return await updateStaffProfile(app, staffId!, {is_silent: silentStatus});
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  } finally {
    if (currentTenant !== 'default') {
      setTenantKey(app, currentTenant!);
    }
  }
};

export const fetchStaffSilentStatus = async (staffId: string) => {
  const currentTenant = localStorage.getItem('tenantId');
  if (currentTenant !== 'default') {
    setTenantKey(app, 'default');
  }
  try {
    const res = await getStaffProfile(app, staffId!);
    console.log('res', res);
    return res?.data?.getStaffProfile?.is_silent;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  } finally {
    if (currentTenant !== 'default') {
      setTenantKey(app, currentTenant!);
    }
  }
};

export const fetchStaffSuspendStatus = async () => {
  const staffId = localStorage.getItem('email');
  try {
    const res = await getStaffProfileTenant(app, staffId!);
    return res?.data?.getStaffProfileTenant?.is_suspended;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};

export const fetchStaffSuspendStatusByTenantId = async (tenantId: string) => {
  const staffId = localStorage.getItem('email');
  const currentTenant = localStorage.getItem('tenantId');

  setTenantKey(app, tenantId);
  try {
    const res = await getStaffProfileTenant(app, staffId!);
    return res?.data?.getStaffProfileTenant?.is_suspended;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  } finally {
    setTenantKey(app, currentTenant!);
  }
};

export const fetchStaffProfileTenantLite = async (tenantId: string) => {
  const staffId = localStorage.getItem('email');
  const currentTenant = localStorage.getItem('tenantId');

  setTenantKey(app, tenantId);
  try {
    const res = await getStaffProfileTenantWithoutNestStaffProfile(
      app,
      staffId!
    );
    return res?.data?.getStaffProfileTenant;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  } finally {
    setTenantKey(app, currentTenant!);
  }
};

export const getCurrentLoggedUser = () => {
  return localStorage.getItem('email') || '';
};

export const updateStaffCustomAttributes = async (
  staffId: string,
  newAttributes: any,
  tenanKey: string
) => {
  const staffProfileTenant = await getStaffProfileCrossTenant(
    app,
    staffId,
    tenanKey
  );

  const customAttribute = JSON.parse(
    staffProfileTenant?.data?.getStaffProfileTenant?.custom_attribute || '{}'
  );

  for (const property in newAttributes) {
    customAttribute[property] = newAttributes[property];
  }

  return await updateStaffProfileCrossTenant(
    app,
    staffId,
    {
      custom_attribute: JSON.stringify(customAttribute),
    },
    tenanKey
  );
};
