/* eslint-disable @typescript-eslint/no-explicit-any */
import {PhotoCamera} from '@mui/icons-material';
import {Box, Button, CircularProgress, Grow} from '@mui/material';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {
  photoIcon,
  qualiBtnContainer,
  tabOneBtnBox,
} from '../../../ProfileViewStyles';
import {useState} from 'react';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {SinTabGeneralProps} from '../../../../../../types/types';
import {useFileUpload} from '../../../../../../hooks/useFileUpload';
import {
  createSinDocument,
  updateSinDocument,
  updateTenantStaffDocuments,
} from '../../../../../../providers/api/documents';
import {useNavigate} from 'react-router-dom';
import {useCurrentUser} from '../../../../../../hooks/useCurrentUser';
import {fetchAgencyList} from '../../../../../../providers/api/tenancy';

export const SinTabTwo = ({
  setSinReg,
  setSinStatus,
  sinDocId,
  inApp,
}: SinTabGeneralProps) => {
  const {handlePopUp, popUp, setPopUp, status, message} = usePopUp();
  const staffId = localStorage.getItem('email');
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const currentUrl = window.location.pathname;
  const {userValid, sessionInvalidRedirection, errMsg} = useCurrentUser();

  const handleSinReg = () => setSinReg && setSinReg(true);

  const handleSinStatus = () => setSinStatus && setSinStatus('cra');

  const createSinDoc = async () => {
    setSubmitting(true);

    const userStatus = await userValid();
    if (!userStatus) {
      handlePopUp('error', errMsg);
      sessionInvalidRedirection();
      return;
    }

    if (!fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    const uploaded: any = await handleFileUpload(staffId!);
    const sinPhoto = uploaded.data.result.Location;

    const craTempNo = '';

    createSinDocument(staffId!, 'cra', craTempNo, null, sinPhoto)
      .then(res => {
        if (res.data.createStaffDocument.id !== null) {
          handleSinStatus();
          handleSinReg();
          finalSubmitAction();
        } else {
          setSubmitting(false);
          handlePopUp('error', 'CRA letter number already registered');
        }
      })
      .catch(err => console.log(err));
  };

  const finalSubmitAction = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification'
    );
    setTimeout(() => {
      if (currentUrl === '/registration/upload/sin') {
        navigate('/info/id');
      } else if (currentUrl === '/document/sin') {
        navigate('/documents');
      }
    }, 700);
  };

  const updateSinDoc = async () => {
    try {
      setSubmitting(true);

      const userStatus = await userValid();
      if (!userStatus) {
        handlePopUp('error', errMsg);
        sessionInvalidRedirection();
        return;
      }

      if (!fileSelected) {
        handlePopUp('error', 'Please upload document');
        setSubmitting(false);
        return;
      }

      const uploaded: any = await handleFileUpload(staffId!);
      const sinPhotoUpload = uploaded?.data?.result?.Location;
      const craTempNo = '';

      const res = await updateSinDocument(
        sinDocId!,
        'cra',
        craTempNo,
        null,
        sinPhotoUpload
      );

      if (inApp) {
        try {
          const tenantList = await fetchAgencyList(staffId!);

          if (tenantList && tenantList.length !== 0) {
            for (const tenantId of tenantList) {
              await updateTenantStaffDocuments(tenantId)(
                res.data.updateStaffDocument.id,
                'sin',
                'cra'
              );
            }
          }
        } catch (err) {
          console.log(err);
          console.log(err);
          return;
        }
      } else {
        handlePopUp(
          'success',
          'Your documents have been uploaded for verification'
        );
      }
      handleSinReg();
      handleSinStatus();
      finalSubmitAction();
    } catch (err) {
      handlePopUp('error', 'Unable to update SIN document');
      console.log(err);
      setSubmitting(false);
    }
  };

  const updateCraStatus = () => (sinDocId ? updateSinDoc() : createSinDoc());

  const handleSubmit = () => {
    updateCraStatus().catch(err => {
      console.log(err);
      console.log(err);
    });
  };

  return (
    <Grow in unmountOnExit>
      <Box sx={{width: '100%', mt: 2}}>
        <Box sx={tabOneBtnBox}>
          <Box sx={{textAlign: 'center', color: 'primary.main', mb: 2}}>
            Please upload your letter from the CRA. You may choose your work
            status as Applied in Eligibility to Work in next section
          </Box>
          <Button
            id="upload-btn"
            variant="outlined"
            sx={continueBtn}
            component="label"
          >
            <PhotoCamera sx={photoIcon} />
            Upload CRA Letter
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={changeHandler}
            />
          </Button>
        </Box>
        <Box sx={qualiBtnContainer}>
          <Button
            id="submit-btn"
            variant="outlined"
            sx={continueBtn}
            disabled={submitting}
            onPointerUp={handleSubmit}
          >
            {submitting ? (
              <CircularProgress
                size="1.3rem"
                sx={{color: 'primary.light', fontSize: '12px'}}
              />
            ) : (
              'Save'
            )}
          </Button>
        </Box>
        <PopUp
          isOpen={popUp}
          onClose={() => setPopUp(false)}
          message={message}
          status={status}
        />
      </Box>
    </Grow>
  );
};
