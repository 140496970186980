/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {ArrowLeft, Verified} from '@mui/icons-material';
import {Box, Button, Checkbox} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {
  continueBtn,
  userScrollLimit,
} from '../../LandingView/LandingViewStyles';
import {flexAlign} from '../../../Private/ProfileView/ProfileViewStyles';
import {
  notVerified,
  requireArrow,
  requireBox,
  requireContainer,
  statusCardBox,
  statusDesc,
  statusTitle,
  verified,
} from '../RegistrationStyles';
import {
  govStatusList,
  listDocumentList,
  sinDocTypeFromGQL,
  sinStatusList,
  workStatusList,
} from '../../../../providers/api/documents';
import {updateStaffProfile} from '@digistaff/staff';
import {app} from '../../../../providers/Accounts';
import {checkBoxLarge} from '../../../../components/common/AvailabilityTable/AvailabilityTableStyles';
import {CustomTextLoad} from '../../../../components/common/CustomTextLoad/CustomTextLoad';

import {handleCustomError} from '../../../RouteErrorView/RouteErrorView';
import {
  checkOnboardingStatus,
  getUserPersonalInfo,
} from '../../../../providers/api/profile';

export const UploadIdView = () => {
  const navigate = useNavigate();
  const [legallyChecked, setLegallyChecked] = useState(false);
  const [validChecked, setValidChecked] = useState(false);
  const [sinReg, setSinReg] = useState(false);
  const [govReg, setGovReg] = useState(false);

  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [workReg, setWorkReg] = useState(false);
  const [sinStatus, setSinStatus] = useState('');
  const [govDocId, setGovDocId] = useState('');
  const [sinDocId, setSinDocId] = useState('');
  const [workDocId, setWorkDocId] = useState('');
  const staffId = sessionStorage.getItem('email');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStatus().catch(err => {
      console.log(err);
    });
    getPersonalInfo();
  }, []);

  const getPersonalInfo = async () => {
    const retrievedProfile = await getUserPersonalInfo(staffId!);
    setResumeUploaded(retrievedProfile?.resume_url ? true : false);
  };
  const getStatus = async () => {
    try {
      const docRes = await listDocumentList(staffId!);

      handleDocStatus(docRes);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDocStatus = (documents: any) => {
    documents.map((doc: any) => {
      if (govStatusList.includes(doc.type)) {
        setGovReg(true);
        setGovDocId(doc.id);
      }

      if (sinStatusList.includes(doc.type)) {
        setSinReg(true);
        setSinDocId(doc.id);
        setSinStatus(sinDocTypeFromGQL[doc.type]);
      }
      //resume list should show here
      doc.type === 'mandatory_sin_cra' && setSinStatus('cra');

      if (workStatusList.includes(doc.type)) {
        setWorkReg(true);
        setWorkDocId(doc.id);
      }
    });
  };

  const handleValidCheck = () => setValidChecked(!validChecked);

  const handleLegallyCheck = () => setLegallyChecked(!legallyChecked);

  const formCompleted = () => {
    if (legallyChecked && validChecked && sinReg && govReg && workReg)
      return false;
    return true;
  };

  const handleNext = async () => {
    try {
      const onboardingStatus = await checkOnboardingStatus();
      if (onboardingStatus === 9) {
        navigate('/review');
      } else {
        // Only update onboarding step if is user's first time visit this page
        if (onboardingStatus === 6) {
          await updateStaffProfile(app, staffId!, {current_onboarding_step: 7});
        }
        navigate('/info/emergency');
      }
    } catch (error) {
      handleCustomError(error);
    }
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/availability" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Work Status and Documents">
          {loading ? (
            <Box sx={{px: 3, mt: 5}}>
              {Array.from(new Array(5)).map((_, index) => (
                <CustomTextLoad height={54} mb={14} key={index} />
              ))}
            </Box>
          ) : (
            <>
              <Box
                sx={{p: 2, display: 'flex', flexDirection: 'column', gap: 2}}
              >
                <Box
                  id="gov-section"
                  sx={statusCardBox}
                  onPointerUp={() =>
                    navigate('/registration/upload/gov', {
                      state: {param1: govDocId},
                    })
                  }
                >
                  <Box sx={flexAlign}>
                    <Verified sx={govReg ? verified : notVerified} />
                    <Box sx={statusTitle}>Government ID</Box>
                    <Box sx={requireContainer}>
                      <ArrowLeft sx={requireArrow} />
                      <Box sx={requireBox}>Required</Box>
                    </Box>
                  </Box>
                  <Box sx={statusDesc}>
                    Please provide government ID, such as Passport and Driver
                    License
                  </Box>
                </Box>
                <Box
                  id="sin-section"
                  sx={statusCardBox}
                  onPointerUp={() =>
                    navigate('/registration/upload/sin', {
                      state: {param1: sinDocId},
                    })
                  }
                >
                  <Box sx={flexAlign}>
                    <Verified sx={sinReg ? verified : notVerified} />
                    <Box sx={statusTitle}>SIN</Box>
                    <Box sx={requireContainer}>
                      <ArrowLeft sx={requireArrow} />
                      <Box sx={requireBox}>Required</Box>
                    </Box>
                  </Box>
                  <Box sx={statusDesc}>
                    Please provide your social insurance number. You can upload
                    your CRA letter here if you do not have SIN.
                  </Box>
                </Box>
                <Box
                  id="resume-section"
                  sx={statusCardBox}
                  onPointerUp={() =>
                    navigate('/registration/upload/resume', {
                      state: {param1: sinDocId},
                    })
                  }
                >
                  <Box sx={flexAlign}>
                    <Verified sx={resumeUploaded ? verified : notVerified} />
                    <Box sx={statusTitle}>Resume</Box>
                    {/* <Box sx={requireContainer}>
                      <ArrowLeft sx={requireArrow} />
                      <Box sx={requireBox}>Required</Box>
                    </Box> */}
                  </Box>
                  <Box sx={statusDesc}>Please provide your resume</Box>
                </Box>
                {sinStatus !== 'CRA' && sinStatus !== '' && (
                  <Box
                    id="work-section"
                    sx={statusCardBox}
                    onPointerUp={() =>
                      navigate('/registration/upload/work', {
                        state: {param1: workDocId},
                      })
                    }
                  >
                    <Box sx={flexAlign}>
                      <Verified sx={workReg ? verified : notVerified} />
                      <Box sx={statusTitle}>Eligibility to Work</Box>
                      <Box sx={requireContainer}>
                        <ArrowLeft sx={requireArrow} />
                        <Box sx={requireBox}>Required</Box>
                      </Box>
                    </Box>
                    <Box sx={statusDesc}>
                      Please indicate your work status and provide relevant
                      documents
                    </Box>
                  </Box>
                )}
              </Box>
              <Box sx={{px: 2, pb: 4}}>
                <Box sx={{display: 'flex', alignItems: 'center', my: 1}}>
                  <Checkbox
                    id="conf-doc-valid"
                    checked={validChecked}
                    onChange={handleValidCheck}
                    sx={checkBoxLarge}
                  />
                  <Box>I certify that my documents are valid</Box>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', my: 1}}>
                  <Checkbox
                    id="conf-legal-to-work"
                    checked={legallyChecked}
                    onChange={handleLegallyCheck}
                    sx={checkBoxLarge}
                  />
                  <Box>I certify that I am legally allowed to work</Box>
                </Box>
                <Box sx={{p: 1}} />
                <Button
                  id="next-btn"
                  variant="outlined"
                  sx={continueBtn}
                  disabled={formCompleted()}
                  onPointerUp={handleNext}
                >
                  Next
                </Button>
              </Box>
            </>
          )}
        </PageWrapper>
      </Box>
    </>
  );
};
