import React, {useState} from 'react';
import {Box, Button, Typography, Paper} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface FileUploaderProps {
  onFileUpload?: (file: File) => void;
}

const FileUploader: React.FC<FileUploaderProps> = ({onFileUpload}) => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    validateAndSetFile(selectedFile);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files?.[0];
    validateAndSetFile(droppedFile);
  };

  const validateAndSetFile = (selectedFile?: File) => {
    if (selectedFile) {
      const allowedTypes = ['application/pdf'];
      if (!allowedTypes.includes(selectedFile.type)) {
        setError('Only PDF is allowed.');
        setFile(null);
        return;
      }
      setError('');
      setFile(selectedFile);
      if (onFileUpload) onFileUpload(selectedFile);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {file && (
        <Box
          sx={{
            mt: 2,
            textAlign: 'center',
            width: '100%',
          }}
        >
          {/* <Typography variant="subtitle1">
            Selected File: {file.name}
          </Typography> */}
          {/* {file.type === 'application/pdf' ? (
              <iframe
                src={URL.createObjectURL(file)}
                title="PDF Preview"
                style={{
                  border: 'border:1px solid red',
                  width: '100%',
                  height: '300px',
                }}
              ></iframe>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Preview not available for this file type
              </Typography>
            )} */}
        </Box>
      )}
      <Paper
        sx={{
          width: '100%',
          maxWidth: '500px',
          height: 'auto',
          minHeight: 100,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          border: '2px dashed #1976d2',
          borderRadius: 2,
          textAlign: 'center',
          p: 1,
          backgroundColor: '#f5f5f5',
        }}
        onDragOver={event => event.preventDefault()}
        onDrop={handleDrop}
      >
        {file && (
          <Box
            sx={{
              mt: 2,
              textAlign: 'center',
              width: '100%',
            }}
          >
            <Typography variant="subtitle1">
              Selected Resume: {file.name}
            </Typography>
          </Box>
        )}
        <Typography variant="body1" color="textSecondary">
          Drag & Drop your resume here or
          <input
            type="file"
            accept=".pdf"
            style={{display: 'none'}}
            id="file-upload"
            onChange={handleFileChange}
          />
          <label htmlFor="file-upload">
            <Button
              component="span"
              variant="contained"
              sx={{ml: 1, borderRadius: 1}}
              startIcon={<CloudUploadIcon />}
            >
              Browse
            </Button>
          </label>
        </Typography>
      </Paper>
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default FileUploader;
